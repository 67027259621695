import React from 'react';
import { graphql } from 'gatsby';
import Grid from '../components/Grid';

import TransformNav from '../components/TransformNav';

import PageTransition from '../components/PageTransition';
import ContentBlocks from '../components/blocks/ContentBlocks';
import SEO from '../components/Seo';
import CoverLogo from '../components/covers/CoverLogo';

export default function playlist({ data }) {
  const { events, page } = data;

  return (
    <div>
      <SEO title={data.page.seo_title} desc={data.page.seo_description} />
      <PageTransition>
        <TransformNav />
        <Grid>
          <div style={{ gridColumn: 'span 12' }}>
            <CoverLogo />
            {page._rawBlocks ? (
              <ContentBlocks blocks={page._rawBlocks} events={events} />
            ) : null}
          </div>
        </Grid>
      </PageTransition>
    </div>
  );
}

export const query = graphql`
  query Speellijst {
    events: allSanityEvent(sort: { fields: startDate, order: ASC }) {
      nodes {
        _createdAt
        _id
        name
        location
        place
        startDate
        endDate
        href
        eventIntro
        slug {
          current
        }
        image {
          asset {
            fluid(maxWidth: 2100) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
    page: sanityPage(slug: { current: { eq: "speellijst" } }) {
      name
      seo_title
      seo_description
      _rawBlocks(resolveReferences: { maxDepth: 10 })
    }
    footer: allSanitySiteSettings {
      edges {
        node {
          address_1
          address_2
          address_3
          facebook_url
          email
          instagram_url
          phone
          studio_title
          title
          title_1
          title_2
          vimeo_url
        }
      }
    }
  }
`;
